import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import moc from "./../assets/images/banner.png"

const BannerOne = ({ data }) => {
  return (
    <section className="banner-style-one" id="banner">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      <img src={moc} className="banner-mock" alt="Awesome alter Text" />
      <Container>
        <Row>
          <Col xl={6} lg={8} sm={12}>
            <div className="content-block">
              <h3 style={{ fontWeight: 'bold' }}>
                {data?.title} <br />
              </h3>
              {/* <p>
              Kini kamu bisa belanja lebih hemat pakai voucher, sambil mengembangkan bisnis dan berbagi keuntungan bersama teman dan keluarga.
              Ayo download KlabBelanja sekarang dan dapatkan bonus untuk setiap pembelian produk!
              </p> */}
              <p>
                <div dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="button-block" style={{ width: '100%' }}>
                  <a href="https://play.google.com/store/apps/details?id=com.klabbelanja&pcampaignid=web_share" className="banner-btn" style={{ width: '100%', textAlign: "center", alignItems: 'center' }}>
                    <i className="fa fa-play"></i>
                    Get in<span>Google Play</span>
                  </a>
                </div>
                <div style={{ width: 10 }} />
                <div className="button-block" style={{ width: '100%' }}>
                  <a href="https://apps.apple.com/id/app/klabbelanja/id6475075475" className="banner-btn" style={{ width: '100%', textAlign: "center", alignItems: 'center' }}>
                    <i className="fa fa-apple"></i>
                    Get in<span>App Store</span>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne
